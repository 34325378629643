import React, { Component } from 'react';
import { navigate, Router, Link } from "@reach/router"

import { withFirebase } from '../Firebase';
import * as ROUTES from '../../constants/routes';

const FormListWithEditor = () => (
  <div className='mt-8 mb-12'>
    <Router>
      <FormList path={ROUTES.FORMS} />
      <FormItem path={ROUTES.FORMS_DETAILS} />
    </Router>
  </div>
);

class FormListBase extends Component {
  _initFirebase = false;

  constructor(props) {
    super(props);

    this.state = {
      loading: false,
      forms: [],
    };
  }

  firebaseInit = () => {
    if (this.props.firebase && !this._initFirebase) {
      this._initFirebase = true;

      this.setState({ loading: true });

      this.props.firebase.forms().on('value', snapshot => {
        const formsObject = snapshot.val();

        const formsList = Object.keys(formsObject).map(key => ({
          ...formsObject[key],
          id: key,
        }));

        this.setState({
          forms: formsList,
          loading: false,
        });
      });
    }
  };

  componentDidMount() {
    this.firebaseInit();
  }

  componentDidUpdate() {
    this.firebaseInit();
  }

  componentWillUnmount() {
    this.props.firebase.forms().off();
  }

  render() {
    const { forms, loading } = this.state;

    return (
      <div>
        <h2 className='text-lg mb-2 font-bold'>List of Forms</h2>

        {loading && <div className='text-center italic text-2xl font-extrabold text-gray-500 px-4 py-6 sm:px-0'>Loading ...</div>}

        <table class="table-auto">
          <tbody>
            {forms.map(form => (
              <tr>
                <td class="border px-4 py-2"><strong>Company:</strong> {form.company}</td>
                <td class="border px-4 py-2"><strong>Paperform ID for New Hire Form:</strong> {form.paperformHire}</td>
                <td class="border px-4 py-2"><strong>Paperform ID for Termination Form:</strong> {form.paperformTerm}</td>
                <td class="border px-4 py-2">
                  <Link to={`${ROUTES.FORMS}/${form.id}`} state={{ form: form, company: form.company, paperformHire: form.paperformHire, paperformTerm: form.paperformTerm }} className='text-blue-900 underline'>
                    Edit
                  </Link>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    );
  }
}

class FormItemBase extends Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: false,
      form: null,
      company: null,
      paperformHire: null,
      paperformTerm: null,
      ...props.location.state,
      editMode: false,
      newCompany: null,
      newPaperformHire: null,
      newPaperformTerm: null,
    };
  }

  componentDidMount() {
    if (this.state.form) {
      return;
    }

    this.setState({
      loading: true,
      company: this.props.company,
      paperformHire: this.props.paperformHire,
      paperformTerm: this.props.paperformTerm,
    });

    this.props.firebase
      .form(this.props.form)
      .on('value', snapshot => {
        this.setState({
          form: snapshot.val(),
          loading: false,
        });
      });
  }

  componentWillUnmount() {
    this.props.firebase.form(this.props.form).off();
  }

  onToggleEditMode = () => {
    this.setState(state => ({
      editMode: !state.editMode,
      newCompany: this.state.form.company,
      newPaperformHire: this.state.form.paperformHire,
      newPaperformTerm: this.state.form.paperformTerm,
    }));
  };

  onChangeEditCompany = event => {
    this.setState({
      newCompany: event.target.value,
    });
  };

  onChangeEditHire = event => {
    this.setState({
      newPaperformHire: event.target.value,
    });
  };

  onChangeEditTerm = event => {
    this.setState({
      newPaperformTerm: event.target.value,
    });
  };

  onEditForm = (company, paperformHire, paperformTerm) => {
    this.props.firebase.form(this.state.form.id).update({
      company: company,
      paperformHire: paperformHire,
      paperformTerm: paperformTerm,
    });
  };

  onSaveEditForm = () => {
    this.onEditForm(this.state.newCompany, this.state.newPaperformHire, this.state.newPaperformTerm);

    this.setState({
      editMode: false,
      company: this.state.newCompany,
      paperformHire: this.state.newPaperformHire,
      paperformTerm: this.state.newPaperformTerm,
    });
  };

  onDeleteForm = () => {
    this.props.firebase.form(this.state.form.id).remove()
    navigate(ROUTES.FORMS);
  }

  render() {
    const { form, loading, editMode, company, newCompany, paperformHire, newPaperformHire, paperformTerm, newPaperformTerm } = this.state;

    return (
      <div className='sm:w-full w-1/3'>
        <h2 className='text-xl mb-6'><strong>Forms for</strong> {company}</h2>
        {loading && <div>Loading ...</div>}

        {form && (
          <div className='flex flex-col'>
            <span className='mb-2'>
              <strong>ID:</strong> {form.id}
            </span>

            {editMode ? (
              <>
                <label className="font-body block text-gray-700 text-sm font-bold mb-2" htmlFor="company">
                  Company Name
                </label>

                <input
                  aria-label='company'
                  type="text"
                  value={newCompany}
                  onChange={this.onChangeEditCompany}
                  className="font-body shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline mb-3"
                />
              </>
            ) : (
              <span className='mb-2'>
                <strong>Company:</strong> {company}
              </span>
            )}

            {editMode ? (
              <>
                <label className="font-body block text-gray-700 text-sm font-bold mb-2" htmlFor="paperformHire">
                  Paperform ID for New Hire Form
                </label>

                <input
                  aria-label='paperformHire'
                  type="text"
                  value={newPaperformHire}
                  onChange={this.onChangeEditHire}
                  className="font-body shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline mb-3"
                />
              </>
            ) : (
              <span className='mb-2'>
                <strong>Paperform ID for New Hire Form:</strong> {paperformHire}
              </span>
            )}

            {editMode ? (
              <>
                <label className="font-body block text-gray-700 text-sm font-bold mb-2" htmlFor="paperformTerm">
                  Paperform ID for Termination Form
                </label>

                <input
                  aria-label='paperformTerm'
                  type="text"
                  value={newPaperformTerm}
                  onChange={this.onChangeEditTerm}
                  className="font-body shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline mb-3"
                />
              </>
            ) : (
              <span className='mb-2'>
                <strong>Paperform ID for Termination Form:</strong> {paperformTerm}
              </span>
            )}

            <div className='mt-6'>
              <span>
                {editMode ? (
                  <div className='flex'>
                    <button
                      type="button"
                      onClick={this.onSaveEditForm}
                      className='group relative w-full flex justify-center py-2 px-4 mr-1 border border-red-900 text-sm leading-5 font-medium rounded-md text-red-900 hover:text-white bg-white hover:bg-red-900 focus:outline-none focus:border-indigo-700 focus:shadow-outline-indigo active:bg-indigo-700 transition duration-150 ease-in-out mb-3'
                    >
                      Save
                    </button>

                    <button
                      type="button"
                      onClick={this.onToggleEditMode}
                      className='group relative w-full flex justify-center py-2 px-4 ml-1 border border-red-900 text-sm leading-5 font-medium rounded-md text-red-900 hover:text-white bg-white hover:bg-red-900 focus:outline-none focus:border-indigo-700 focus:shadow-outline-indigo active:bg-indigo-700 transition duration-150 ease-in-out mb-3'
                    >
                      Cancel
                    </button>
                  </div>
                ) : (
                  <button
                    type="button"
                    onClick={this.onToggleEditMode}
                    className='group relative w-full flex justify-center py-2 px-4 border border-red-900 text-sm leading-5 font-medium rounded-md text-red-900 hover:text-white bg-white hover:bg-red-900 focus:outline-none focus:border-indigo-700 focus:shadow-outline-indigo active:bg-indigo-700 transition duration-150 ease-in-out mb-3'
                  >
                    Edit Data
                  </button>
                )}
              </span>

              <span>
                <Link to='/forms'>
                  <button
                    type="button"
                    className='group relative w-full flex justify-center py-2 px-4 border border-transparent text-sm leading-5 font-medium rounded-md text-white bg-red-800 hover:bg-red-900 focus:outline-none focus:border-indigo-700 focus:shadow-outline-indigo active:bg-indigo-700 transition duration-150 ease-in-out'
                  >
                    Back to Forms Dashboard
                  </button>
                </Link>
              </span>
            </div>

            <div className='mt-12 border-t pt-6'>
              <span>
                <button
                  type="button"
                  onClick={this.onDeleteForm}
                  className='group relative w-full flex justify-center py-2 px-4 border border-gray-500 text-sm uppercase font-semibold leading-5 rounded-md text-gray-500 hover:text-white bg-white hover:bg-black focus:outline-none focus:border-indigo-700 focus:shadow-outline-indigo active:bg-indigo-700 transition duration-150 ease-in-out mb-3'
                >
                  Delete All Data
                </button>
              </span>
            </div>
          </div>
        )}
      </div>
    );
  }
}

const FormList = withFirebase(FormListBase);
const FormItem = withFirebase(FormItemBase);

export default FormListWithEditor