import React from 'react';
import { compose } from 'recompose';
import { Link } from 'gatsby'

import Layout from '../components/layout';
import {
  withAuthorization,
} from '../components/Session';
import FormListWithEditor from '../components/Forms/FormListWithEditor';
import * as ROLES from '../constants/roles';
import Navigation from '../components/Navigation';

const FormsPageBase = () => (
  <div>
    <Navigation />

    <header className="bg-white shadow">
      <div className="max-w-7xl mx-auto py-6 px-4 sm:px-6 lg:px-8">
        <h1 className="text-3xl font-bold leading-tight text-gray-900">
          Forms Dashboard
        </h1>
        <p>
          The Forms Page is accessible to every signed in admin user.
        </p>
      </div>
    </header>

    <main>
      <div className="max-w-7xl mx-auto py-6 sm:px-6 lg:px-8">
        <div className='sm:w-full w-11/12 mx-auto'>
          <div className='mt-12 mb-12'>
            <div className='sm:w-full w-1/3'>
              <Link to='/add-forms'>
                <button className='group relative w-full flex justify-center py-2 px-4 mb-8 border border-transparent text-sm leading-5 font-medium rounded-md text-white bg-red-800 hover:bg-red-900 focus:outline-none focus:border-indigo-700 focus:shadow-outline-indigo active:bg-indigo-700 transition duration-150 ease-in-out'>
                  Add New Forms
                </button>
              </Link>
            </div>

            <hr/>

            <FormListWithEditor />
          </div>
        </div>
      </div>
    </main>
  </div>
);

const condition = authUser =>
  authUser && !!authUser.roles[ROLES.ADMIN];

const FormsPage = compose(
  withAuthorization(condition),
)(FormsPageBase);

export default () => (
  <Layout>
    <FormsPage />
  </Layout>
);
